// eslint-disable-next-line no-restricted-imports
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

import { ORG_COMPLIANCE_ERRORS } from '@endaoment-frontend/constants';
import { Address } from '@endaoment-frontend/types';

export const getCurrency = (value?: number | string, fraction = 2, compact = false): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: compact ? 'compact' : undefined,
    maximumFractionDigits: fraction,
    minimumFractionDigits: fraction,
  });

  return formatter.format(Number(value || 0));
};
export const getFormattedMoney = (num: number) => getCurrency(num, undefined, true).toLowerCase();

const numberFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0, notation: 'compact' });
export const getFormattedNumber = (num: number) => numberFormatter.format(num).toLowerCase();

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'long',
});
export const getDate = (dateStr: string) => dateFormatter.format(new Date(dateStr));

export const getShortAddress = (address: Address) =>
  address.length > 6 ? `${address.slice(0, 5)}...${address.slice(-3)}` : address;

export const getFormattedEIN = (ein: string): string => `${ein.slice(0, 2)}-${ein.slice(2)}`;
export const getNormalizedEIN = (ein?: string): string => (ein || '').replace(/\D/gi, '');

/**
 * @param errorsString Error list spaced by ' :: ' between errors
 * @returns The first or only error extracted from `errorsString`
 * @TODO: this should be an array of errors
 */
export const getOrgComplianceError = (errorsString?: string): string => {
  if (!errorsString) return '';

  const errors = errorsString.split(' :: ');

  return ORG_COMPLIANCE_ERRORS[errors[0] as keyof typeof ORG_COMPLIANCE_ERRORS];
};

export const parseHtml = (html?: string) =>
  parse(
    sanitizeHtml(html || '', {
      allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        img: ['src', 'alt', 'style'],
        h3: ['style'],
        p: ['style'],
        strong: ['style'],
        small: ['style'],
      },
    }),
  );
