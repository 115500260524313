import clsx from 'clsx';
import Link from 'next/link';

import { ArrowIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill } from '@endaoment-frontend/ui/shared';

import styles from './TextGridWithLogo.module.scss';
import { TextGridWithLogoProps, TextItemProps } from './TextGridWithLogo.types';

const TextItem = ({
  header,
  text,
  buttonText,
  buttonArrow = true,
  comingSoon = false,
  href,
  className,
}: TextItemProps) => {
  return (
    <div className={clsx(styles['text-item'], className)}>
      <h5 className={clsx(comingSoon && styles['coming-soon'])}>{header}</h5>
      <hr />
      <p>{text}</p>
      {buttonText && href && (
        <Link href={href} passHref>
          <Button as="a" target="_blank" rel="noreferrer">
            {buttonText}
            {buttonArrow && <ArrowIcon width={20} height={12} />}
          </Button>
        </Link>
      )}
    </div>
  );
};

export const TextGridWithLogo = ({ headerText, pillText, children, className }: TextGridWithLogoProps) => {
  return (
    <section className={clsx(styles['text-grid-container'], className)}>
      <LogoIcon color="#8478E0" width={62} />
      <Pill variation="orange" size="large" className={styles['pill']}>
        {pillText}
      </Pill>
      <h3>{headerText}</h3>
      <div className={styles['text-grid']}>{children}</div>
    </section>
  );
};

TextGridWithLogo.Item = TextItem;
