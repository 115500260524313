import axios from 'axios';
import { BigNumber } from 'ethers';

import { config } from '@endaoment-frontend/config';
import { TOKEN_STORAGE_KEY } from '@endaoment-frontend/constants';
import {
  Fund,
  Org,
  CreateFundInput,
  GetAuthTokenInput,
  DeployOrgInput,
  Claim,
  CreateWalletClaimInput,
  CreateWireClaimInput,
  Token,
  Donation,
  Grant,
  CreateDonationInput,
  User,
  GasEstimation,
  SwapInfo,
  CreateGrantInput,
  NTEECode,
  DeployOrgsInput,
  Withdrawal,
  ApproveClaimInput,
  RejectClaimInput,
  ApproveGrantInput,
  RejectGrantInput,
  ClaimOrgInput,
  PayoutInput,
  EmailRequestInput,
  EmailSendInput,
  EmailFields,
  OTCAddress,
} from '@endaoment-frontend/types';

export const client = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  transformRequest: [
    (data, headers) => {
      let token: string | null = null;

      if (typeof window !== 'undefined') {
        token = window.localStorage.getItem(TOKEN_STORAGE_KEY);
      }

      // eslint-disable-next-line no-param-reassign
      if (token && headers) headers['Authorization'] = `Bearer ${token}`;

      return data;
    },
    // @ts-expect-error always array
    ...axios.defaults.transformRequest,
  ],
});

// Simple error handling for api in dev
if (process.env['NODE_ENV'] === 'development') {
  client.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.code === 'ECONNREFUSED') {
        console.warn('API is not running');
        return null;
      }
      return Promise.reject(err);
    },
  );
}

export const api = {
  // ************
  // ** Auth
  // ************
  GetAuthToken: {
    key: 'GetAuthToken',
    execute: (input: GetAuthTokenInput) => client.post<{ token: string }>('/auth', input),
  },

  // ************
  // ** Me (Auth'd user)
  // ************
  GetMyFunds: {
    key: 'GetMyFunds',
    execute: () => client.get<Fund[]>(`/me/funds`),
  },
  GetMyDonations: {
    key: 'GetMyDonations',
    execute: () => client.get<Donation[]>('/me/donations'),
  },
  GetMyClaims: {
    key: 'GetMyClaims',
    execute: () => client.get<Claim[]>('/me/claims'),
  },
  GetMe: {
    key: 'GetMe',
    execute: () => client.get<User>(`/me`),
  },

  // ************
  // ** Funds
  // ************
  GetFeaturedFunds: {
    key: 'GetFeaturedFunds',
    // @TODO: verify this - changes standard for all api calls
    /** @deprecated */
    execute: () => client.get<Fund[]>('/funds/public/featured'),
    query: async () => {
      const res = await client.get<Fund[]>('/funds/public/featured');
      return res.data;
    },
  },
  GetPublicFunds: {
    key: 'GetPublicFunds',
    execute: () => client.get<Fund[]>('/funds/public'),
  },
  GetFundByVanityUrl: {
    key: 'GetFundByVanityUrl',
    execute: (vanityUrl: string) => client.get<Fund>(`/funds/vanity/${vanityUrl}`),
  },
  EstimateFundGas: {
    key: 'EstimateFundGas',
    execute: () => client.get<GasEstimation>('/funds/estimate'),
  },
  CreateFund: {
    key: 'CreateFund',
    execute: (input: CreateFundInput) => client.post<Fund>('/funds', input),
  },
  GetFund: {
    key: 'GetFund',
    execute: (id: string) => client.get<Fund>(`/funds/${id}`),
  },

  // ************
  // ** Orgs
  // ************
  GetFeaturedOrgs: {
    key: 'GetFeaturedOrgs',
    /** @deprecated */
    execute: () => client.get<Org[]>('/orgs/featured'),
    query: async () => {
      const res = await client.get<Org[]>('/orgs/featured');
      return res.data;
    },
  },
  GetDeployedOrgs: {
    key: 'GetDeployedOrgs',
    /** @deprecated */
    execute: () => client.get<Org[]>('/orgs/deployed'),
    query: async () => {
      const res = await client.get<Org[]>('/orgs/deployed');
      return res.data;
    },
  },
  SearchOrgs: {
    key: 'SearchOrgs',
    execute: (searchTerm: string, code?: NTEECode, itemsPerPage?: number, offset?: number) =>
      client.post<Org[]>('/orgs/search', {
        searchTerm,
        code,
        itemsPerPage,
        offset,
      }),
  },
  GetOrgByEin: {
    key: 'GetOrgByEin',
    /** @deprecated */
    execute: (ein: string) => client.get<Org>(`/orgs/${ein}`),
    query: async (ein: string) => {
      if (/^\d{2}-?\d{7}$/.test(ein)) {
        const res = await client.get<Org>(`/orgs/${ein.replace(/-/g, '')}`);
        if (res.data) return res.data;
      }
      return undefined;
    },
  },
  GetOrgById: {
    key: 'GetOrgById',
    execute: (id: string) => client.get<Org>(`/orgs/id/${id}`),
  },
  EstimateOrgGas: {
    key: 'EstimateOrgGas',
    execute: () => client.get<GasEstimation>('/orgs/estimate'),
  },
  DeployOrg: {
    key: 'DeployOrg',
    execute: (input: DeployOrgInput) => client.post<Org>('/orgs', input),
  },

  // ************
  // ** Donations
  // ************
  GetTotalDonations: {
    key: 'GetTotalDonations',
    /** @deprecated */
    execute: () => client.get<{ totalDonations: number }>('/donations/total'),
    query: async () => {
      const res = await client.get<{ totalDonations: number }>('/donations/total');
      return res.data.totalDonations;
    },
  },
  GetDestinationDonations: {
    key: 'GetDestinationDonations',
    execute: (id: string) => client.get<Donation[]>(`/donations/destination/${id}`),
  },
  GetMinDonation: {
    key: 'GetMinDonation',
    execute: () => client.get<{ MIN_DONATION: number }>(`/donations/min`),
  },
  CreateDonation: {
    key: 'CreateDonation',
    execute: (input: CreateDonationInput) => client.post<Donation>('/donations', input),
  },

  // ************
  // ** Grants
  // ************
  GetTotalGrants: {
    key: 'GetTotalGrants',
    /** @deprecated */
    execute: () => client.get<{ totalGrants: number }>('/grants/total'),
    query: async () => {
      const res = await client.get<{ totalGrants: number }>('/grants/total');
      return res.data.totalGrants;
    },
  },
  GetFundGrants: {
    key: 'GetFundGrants',
    execute: (id: string) => client.get<Grant[]>(`/grants/fund/${id}`),
  },
  GetOrgGrants: {
    key: 'GetOrgGrants',
    execute: (id: string) => client.get<Grant[]>(`/grants/org/${id}`),
  },
  CreateGrant: {
    key: 'CreateGrant',
    execute: (input: CreateGrantInput) => client.post<Grant>('/grants', input),
  },

  // ************
  // ** Claims
  // ************
  CreateClaim: {
    key: 'CreateClaim',
    execute: (input: CreateWalletClaimInput | CreateWireClaimInput) => client.post<Claim>('/claims', input),
  },

  // ************
  // ** Tokens & Swap Info
  // ************
  GetTokens: {
    key: 'GetTokens',
    execute: (chainId: number) => client.get<Token[]>(`/tokens?chainId=${chainId}`),
  },
  GetSwapInfo: {
    key: 'GetSwapInfo',
    execute: (token: Token, amount: BigNumber) =>
      client.get<SwapInfo>(
        `/swap-info?amount=${amount.toString()}${token.address ? `&tokenAddress=${token.address}` : ''}`,
      ),
  },

  // ************
  // ** OTC Tokens & Info
  // ************
  GetOTCAddresses: {
    key: 'GetOTCAddresses',
    /** @deprecated */
    execute: () => client.get<OTCAddress[]>('/tokens/otc'),
    query: async () => {
      const res = await client.get<OTCAddress[]>('/tokens/otc');
      return res.data;
    },
  },

  // ************
  // ** ADMIN
  // ** @TODO: API currently doesn't have all admin routes inside /admin - so routes here can be intentionally missing /admin
  // ************
  AdminGetAllFunds: {
    key: 'AdminGetAllFunds',
    execute: () => client.get<Fund[]>('/admin/funds'),
  },
  AdminGetAllGrants: {
    key: 'AdminGetAllGrants',
    execute: () => client.get<Grant[]>('/admin/grants'),
  },
  AdminGetAllDonations: {
    key: 'AdminGetAllDonations',
    execute: () => client.get<Donation[]>('/admin/donations'),
  },
  AdminGetAllWithdrawals: {
    key: 'AdminGetAllWithdrawals',
    execute: () => client.get<Withdrawal[]>('/admin/withdrawals'),
  },
  AdminGetAllClaims: {
    key: 'AdminGetAllClaims',
    execute: () => client.get<Claim[]>('/admin/claims'),
  },
  AdminDeployOrgs: {
    key: 'AdminDeployOrgs',
    execute: (input: DeployOrgsInput) => client.post<Org[]>('/orgs/deployer', input),
  },
  AdminApproveGrant: {
    key: 'AdminApproveGrant',
    execute: (input: ApproveGrantInput) => client.patch<Grant>(`/grants/${input.grantId}/approve`, input),
  },
  AdminRejectGrant: {
    key: 'AdminRejectGrant',
    execute: (input: RejectGrantInput) => client.patch<Grant>(`/grants/${input.grantId}/reject`, input),
  },
  AdminApproveClaim: {
    key: 'AdminApproveClaim',
    execute: (id: string, input: ApproveClaimInput) => client.patch<Claim>(`/claims/${id}/approve`, input),
  },
  AdminRejectClaim: {
    key: 'AdminRejectClaim',
    execute: (id: string, input: RejectClaimInput) => client.patch<Claim>(`/claims/${id}/reject`, input),
  },
  AdminClaimOrg: {
    key: 'AdminClaimOrg',
    execute: (id: string, input: ClaimOrgInput) => client.patch<Org>(`/orgs/${id}`, input),
  },
  AdminPayout: {
    key: 'AdminPayout',
    execute: (input: PayoutInput) => client.post<Withdrawal>(`/withdrawals`, input),
  },
  AdminRequestEmail: {
    key: 'AdminRequestEmail',
    execute: (input: EmailRequestInput) => client.get<EmailFields>(`/admin/email`, { params: input }),
  },
  AdminSendEmail: {
    key: 'AdminSendEmail',
    execute: (input: EmailSendInput) => client.post(`/admin/email/direct`, input),
  },
  AdminVerifyBank: {
    key: 'AdminVerifyBank',
    execute: (id: string) => client.get(`/claims/verifyBank/${id}`),
  },
};
