import clsx from 'clsx';

import { config } from '@endaoment-frontend/config';
import { Button, Card } from '@endaoment-frontend/ui/shared';

import styles from './SMEINLookup.module.scss';
import { useEINLookup } from './useEINLookup';

export const SMEINLookup = ({ className }: { className?: string }) => {
  const { displayedEIN, lookup, org, failed } = useEINLookup();

  return (
    <Card className={clsx(styles['ein-lookup'], className)}>
      <div>
        <h3>
          {org ? (
            <a href={`${config.clientBaseUrl}/orgs/${org.ein}`} target="_blank" rel="noreferrer">
              <b>{org.name}</b>
            </a>
          ) : (
            'Nonprofit Lookup'
          )}
        </h3>
        <p>
          {org ? (
            <span>
              {`${org.orgAddress ? `${org.orgAddress}, ` : ''}${org.orgCity}, ${org.orgCity}, ${org.orgState}`}
            </span>
          ) : (
            'Claim your 501(c)(3) organization to receive donated funds'
          )}
        </p>
      </div>
      <hr />
      <div>
        <input value={displayedEIN} onChange={(e) => lookup(e.target.value)} placeholder="12-3456789" />
        <div>
          {org ? (
            <Button
              as="a"
              variation="org"
              filled
              href={`${config.clientBaseUrl}/orgs/${org.ein}${org.claimed ? '' : '/claim'}`}
              target="_blank"
              rel="noreferrer"
              className={styles['ein-lookup-button']}
            >
              {org.claimed ? 'Go to Org' : 'Claim Org'}
            </Button>
          ) : (
            <Button as="span" variation="org" filled className={styles['ein-lookup-button']}>
              {failed ? 'Invalid EIN' : 'Enter EIN'}
            </Button>
          )}
          <Button
            as="a"
            variation="fund"
            filled
            href={`${config.clientBaseUrl}/orgs`}
            target="_blank"
            rel="noreferrer"
            className={styles['ein-lookup-button']}
          >
            Search All
          </Button>
        </div>
      </div>
    </Card>
  );
};
