import { useQuery } from 'react-query';

import { api } from '@endaoment-frontend/api';
import { Card } from '@endaoment-frontend/ui/shared';
import { getFormattedMoney, getFormattedNumber } from '@endaoment-frontend/utils';

import styles from './SMStatsCard.module.scss';

export const SMStatsCard = () => {
  const { data: totalDonations } = useQuery(api.GetTotalDonations.key, api.GetTotalDonations.query);
  const { data: totalGranted } = useQuery(api.GetTotalGrants.key, api.GetTotalGrants.query);
  // TODO: Use a less data intensive endpoint (needs to be created?)
  const { data: deployedOrgs } = useQuery(api.GetDeployedOrgs.key, api.GetDeployedOrgs.query);

  const deployedOrgsCount = deployedOrgs?.length ?? 0;
  const averageGrantSize = totalGranted / deployedOrgsCount;

  return (
    <>
      <Card className={styles['stats-card']}>
        <div>
          <h3>{getFormattedMoney(totalDonations)}</h3>
          <span>Total Donations</span>
        </div>
        <div>
          <h3>{getFormattedMoney(totalGranted)}</h3>
          <span>Total Granted</span>
        </div>
        <div>
          <h3>{getFormattedNumber(deployedOrgsCount)}</h3>
          <span>Orgs Deployed</span>
        </div>
        <div>
          <h3>{getFormattedMoney(averageGrantSize)}</h3>
          <span>Average Grant Size</span>
        </div>
      </Card>
    </>
  );
};
