import { useState } from 'react';
import { useQuery } from 'react-query';

import { api } from '@endaoment-frontend/api';
import { Org } from '@endaoment-frontend/types';

export const useEINLookup = () => {
  const [lookupVal, setLookupVal] = useState('');
  const { data: org, error } = useQuery<Org | undefined, Error>([api.GetOrgByEin.key, lookupVal], () =>
    api.GetOrgByEin.query(lookupVal),
  );

  const lookup = (ein: string) => {
    if (ein.length <= 10) {
      ein = ein.replace(/-/g, '');
      setLookupVal(ein.length > 2 ? ein.slice(0, 2) + '-' + ein.slice(2) : ein);
    }
  };

  return {
    org,
    failed: !org && error,
    displayedEIN: lookupVal,
    lookup,
  };
};
